/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactHtmlParser from 'react-html-parser';
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Divider,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

import { ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import Snackbar from "./CustomSnackbar";
import ModalReservados from "./ModalReservados";
import ModalPicking from "./ModalPicking";
import ModalContainer from "./ModalContainer";
import ModalEmbarcado from "./ModalEmbarcado";
import ModalBloqueado from "./ModalBloqueado";
import ModalProduzindo from "./ModalProduzindo";
import { getUrlImagemEmbalabem } from "../Utils/Url";
import { parseNumberFormat } from "../Utils/Format";
import { EstruturaCompleta } from "./EstruturaCompleta";
import { BarraOffline } from "./BarraOffline";
import { SearchProductByName } from "./SearchProductsByName";
import { parseJwt, validateToken } from "../Utils/Cookie";
import axios from "axios";
import ModalFabricando from "./ModalFabricando";
import ModalFabricado from "./ModalFabricado";
import ModalBid from "./ModalBid";
import ModalTabelaFob from "./ModalTabelaFob";
import ModalCaracteristicas from "./ModalCaracteristicas";
import { BsInfoCircle } from "react-icons/bs";


import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { createStyles, makeStyles } from "@material-ui/core";
import ModalTransito from './ModalTransito';
import ModalQualidade from './ModalQualidade';
const isDesktop = window.screen.width > 940;

const useStyles = makeStyles(() =>
  createStyles({
    fotoProduto: {},
    box: {
      marginBottom: 15,
    },
    controlLabel: {
      marginBottom: 15,
      marginLeft: 0,
    },
    scrolled: {
      marginBottom: 15,
      textAlign: "left",
      "@media (min-width: 1280px)": {
        marginRight: 24,
      },
    },
    loading: {
      display: "flex",
      "& > div": {
        marginLeft: "10px",
      },
    },
    headerRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headerTitle: {
      display: "flex",
      alignItems: isDesktop ? "center" : "normal",
      [isDesktop ? "" : "flexDirection"]: "column",
      [isDesktop ? "" : "marginRight"]: "5px",
      [isDesktop ? "" : "marginLeft"]: "5px",
    },
    selectRoot: {
      marginRight: "10px",
      [isDesktop ? "" : "marginLeft"]: "10px",
      minWidth: "100px",
    },
    boxProduto: {
      display: "flex",
      alignItems: "center",
      width: isDesktop ? "64%" : "100%",
      [isDesktop ? "" : "flexDirection"]: "column",
    },
    boxEstruturaKit: {
      display: "flex",
      width: isDesktop ? "35%" : "94%",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      [isDesktop ? "" : "margin"]: "0 auto",
    },
    boxSearch: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    textBusca: {
      width: isDesktop ? "140px" : "100%",
    },
    fieldsSearch: {
      display: "flex",
      [isDesktop ? "" : "flexDirection"]: "column",
    },
    textDescricao: {
      display: "flex",
      textAlign: "left",
      flexDirection: "column",
      [isDesktop ? "" : "marginTop"]: "10px",
      [isDesktop ? "" : "marginBottom"]: "10px",
    },
    logoff: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "10px",
      marginBottom: "10px",
    },
    btPrevios: {
      position: "absolute",
      left: "10px",
    },
    btNext: {
      position: "absolute",
      right: "10px",
    },
  })
);

export default function Espelho() {
  const history = useHistory();
  const paramsUrl = useParams();
  const classes = useStyles();
  const [offline, setOffline] = useState(false);
  const [empresa, setEmpresa] = useState(1);
  const [schema, setSchema] = useState([]);
  const [codigoBusca, setCodigoBusca] = useState("");
  const [descricaoBusca, setDescricaoBusca] = useState("");
  const [produto, setProduto] = useState();
  const [totalProduzindo, setTotalProduzindo] = useState(0);
  const [totalContainer, setTotalContainer] = useState(0);
  const [totalEmbarcado, setTotalEmbarcado] = useState(0);
  const [loadingEspelho, setLoadingEspelho] = useState();
  const [loadingNarwal, setLoadingNarwal] = useState();
  const [rowsContainer, setRowsContainer] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [messageSnack, setMessageSnack] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [variantSnack, setVariantSnack] = useState("info");
  const [durationSnack, setDurationSnack] = useState(20000);
  const [statePainels, setStatePainels] = useState([]);
  const [isNacional, setIsNacional] = useState(false);
  const [totalBid, setTotalBid] = useState(0);
  const [loadingData, setLoadingData] = useState(false)
  const [loadingStructureKit, setLoadingStructureKit] = useState(false)
  const [estruturaCompleta, setEstruturaCompleta] = useState([])
  const [totalProcUnKit, setTotalProcUnkit] = useState(0)
  const [isKit, setIsKit] = useState(false)
  const [isUnKit, setIsUnKit] = useState(false)


  const msgToolTipDispFat = 'Físico - Pedidos em Carteira + Campanha Estimada + Em Trânsito + Em Qualidade + Em Inspeção + Em Revisão'
  const msgToolTipDispCar = 'Físico - Pré-Faturas em Carga (Reserva Exclusiva) - Reserva Para Pedido de Venda + Em Revisão'

  const endpoint_api = process.env.REACT_APP_API;

  const handleSnackbar = ({ message, variant = 'info', duration = 20000 }) => {
    setMessageSnack(message)
    setVariantSnack(variant)
    setDurationSnack(duration)
    setOpenSnack(true)
  }

  const findNext = () => {
    if (!loadingEspelho) {
      const newCodigo = Number(codigoBusca) + 1;
      setCodigoBusca(newCodigo);
      history.push(`/produto/${newCodigo}`);
      setProduto(undefined)
      fillProduto(newCodigo, empresa);
    }
  };

  const findPrevious = () => {
    if (!loadingEspelho) {
      const newCodigo = Number(codigoBusca) - 1;
      setCodigoBusca(newCodigo);
      history.push(`/produto/${newCodigo}`);
      setProduto(undefined)
      fillProduto(newCodigo, empresa);
    }
  };

  const goToProductKit = (product) => {
    window.scrollTo(0, 0);
    history.push(`/produto/${product}`);
    setProduto(undefined);
    fillProduto(product, empresa);
    setCodigoBusca(String(product));
  };

  const setSchemaMirror = (schema, groups) => {
    const isRca = groups.some((group) => group.displayName === "Espelho - RCA");
    const isImportacao = groups.some(
      (group) => group.displayName === "Espelho - Importação"
    );

    const isAdmin = groups.some(
      (group) => group.displayName === "Espelho - Admin"
    );
    const isDiretoria = groups.some(
      (group) => group.displayName === "Espelho - Diretoria"
    );

    const isOtherUsers = !(
      isRca ||
      isImportacao ||
      isAdmin ||
      isDiretoria
    );

    if (isAdmin || isDiretoria || isImportacao) {
      setSchema(schema);
      setPermissions(groups);
    } else {
      if (isRca) {
        //remove importacao
        const indexImportacao = schema.findIndex(
          (schema) => schema.name === "Importação"
        );
        schema.splice(indexImportacao, 1);
      }

      if (isOtherUsers) {
        const indexImportacao = schema.findIndex(
          (schema) => schema.name === "Importação"
        );
        if (indexImportacao !== -1) {
          const indexFabricante = schema[indexImportacao].fields.findIndex(
            (field) => field.label === "FABRICANTE"
          );
          schema[indexImportacao].fields.splice(indexFabricante, 1);

          const indexRefFabricante = schema[indexImportacao].fields.findIndex(
            (field) => field.label === "REF FABRICANTE"
          );
          schema[indexImportacao].fields.splice(indexRefFabricante, 1);
        }
      }
      setSchema(schema);
      setPermissions(groups);
    }
  };

  useEffect(() => {
    const codEmp = localStorage.getItem("codigodaempresa");
    if (!validateToken()) {
      localStorage.clear();
      localStorage.setItem("codigodaempresa", codEmp);
      history.push("/login?logout=true");
      return;
    }
    const { groups, schema } = parseJwt();
    setEmpresa(codEmp);
    setSchemaMirror(schema, groups);

    if (paramsUrl && paramsUrl.codigoProduto) {
      setProduto(undefined)
      fillProduto(paramsUrl.codigoProduto, codEmp);
      setCodigoBusca(paramsUrl.codigoProduto);
    }

    const storageStatePanel = localStorage.getItem("statePanels")
      ? JSON.parse(localStorage.getItem("statePanels"))
      : [];
    setStatePainels(storageStatePanel);
  }, []);

  const handleChangeEnterprise = (codEmp) => {
    localStorage.setItem("codigodaempresa", String(codEmp));
    setEmpresa(codEmp);
    if (codigoBusca) {
      setCodigoBusca("");
      history.push("/");
    }
    setDescricaoBusca("");
    setProduto(undefined);
    setTotalProduzindo(0);
    setTotalContainer(0);
    setRowsContainer([]);
  };

  const getNow = (valueParse = undefined) => {
    let nowTemp = new Date()

    if (valueParse) {
      nowTemp = valueParse && typeof valueParse === 'string'
        ? new Date(valueParse)
        : valueParse;
    }
    const time = nowTemp.toLocaleTimeString("pt-br");
    const dateTemp = nowTemp.toLocaleDateString("pt-br").split("/");
    return `${dateTemp[2]}-${dateTemp[1]}-${dateTemp[0]} ${time}`;
  };

  const parseDate = (dateString) => {
    const dateParts = dateString.split("/");
    const formattedDate = new Date(
      `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
    );
    return formattedDate;
  };

  const isDateLess = (dateA, dateB) => {
    const compare1 = dateA.getTime();
    const compare2 = dateB.getTime();

    return compare1 <= compare2;
  };

  const getDateFab = (fabricando) => {
    let datFab = "";
    const now = new Date().toLocaleDateString("pt-br");
    const nowSplit = now.split("/");
    const dateCompare = new Date(
      `${nowSplit[2]}-${nowSplit[1]}-${nowSplit[0]} 00:00:00`
    );
    const isAllDateLessNow = fabricando.every((item) =>
      isDateLess(parseDate(item.datPro), dateCompare)
    );
    const fabricadoNow = fabricando.find((item) => item.datPro === now);

    if (fabricadoNow) {
      return fabricadoNow.datPro;
    }

    if (isAllDateLessNow) {
      return fabricando[fabricando.length - 1]?.datPro || "";
    }
    for (const item of fabricando) {
      if (!isDateLess(parseDate(item.datPro), dateCompare)) {
        datFab = item.datPro;
        break;
      }
    }
    return datFab;
  };

  const getQtdFabByDate = (fabricando) => {
    const datFab = getDateFab(fabricando);
    let total = 0;

    for (const item of fabricando) {
      if (item.datPro === datFab) {
        total += item.qtdPro;
      }
    }

    return total;
  };

  const getIsNacional = (produto) => {
    const origensNacional = [0, 3, 4, 5, 8]
    return produto && origensNacional.includes(Number(produto.oriMer))
  }

  const getField = (campo) => {
    if (!havePermissionToField(campo.label)) return <></>

    const { totFab, totNot } = produto;
    let { fabricando, fabricados } = produto

    switch (campo.type) {
      case "caracteristicas":
        if (loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (produto && produto.segmentacao.length > 0) {
          return (
            <ModalCaracteristicas
              caracteristicas={produto?.segmentacao || []}
              history={history}
              handleSnackbar={handleSnackbar}
            />
          )
        }
        return <div>{`Aplicação: 0`}</div>;

      case "transito":
        if (loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (produto && produto.totBloqueado > 0) {
          return (
            <ModalTransito
              codPro={produto?.codPro || ''}
              permissions={permissions}
              history={history}
              totTransitoWms={produto?.totTransitoWms}
              totBloqueado={produto?.totBloqueado}
              handleSnackbar={handleSnackbar}
            />
          )
        }
        return <div>{`Em Trânsito: 0`}</div>;

      case "qualidade":
        if (loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (produto && produto.totQualidade > 0) {
          return (
            <ModalQualidade
              codPro={produto?.codPro || ''}
              permissions={permissions}
              history={history}
              totQualidade={produto?.totQualidade}
              handleSnackbar={handleSnackbar}
            />
          )
        }
        return <div>{`Em Qualidade: 0`}</div>;

      case "bid_fornecedores":
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {'Total em Cotação'}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (totalBid > 0) {
          return (
            <ModalBid
              qtd={totalBid}
              codPro={produto?.codPro}
              codEmp={empresa}
              permissions={permissions}
              handleSnackbar={handleSnackbar}
            />
          );
        }
        return <div>{`Total em Cotação: ${totalBid}`}</div>;
      case "promo":
        const promo = Number(_.get(produto, campo.value));
        return (
          <FormControlLabel
            className={classes.controlLabel}
            labelPlacement="start"
            label={
              campo.label +
              (promo > 0
                ? `: ${promo.toLocaleString("pt-br", {
                  maximumFractionDigits: 2,
                })}%`
                : "")
            }
            control={
              <Checkbox
                color="primary"
                checked={promo > 0}
                style={{ padding: "0" }}
              />
            }
          />
        );
      case "checkbox":
        const value = _.get(produto, campo.value)
        const valueComparator = campo.label === 'Inativo' ? 'I' : 'S'
        const inativo = typeof value === 'boolean'
          ? _.get(produto, value)
          : value?.toString()?.toUpperCase()?.substr(0, 1) === valueComparator

        return (
          <FormControlLabel
            className={classes.controlLabel}
            labelPlacement="start"
            label={campo.label}
            control={
              <Checkbox
                color="primary"
                checked={inativo}
                style={{ padding: "0" }}
              />
            }
          />
        );
      case "currency":
        return (
          <div>
            {campo.label}:{" "}
            {Number(_.get(produto, campo.value)).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </div>
        );
      case "unidadesDespacho":
        const unidadesDespacho = _.get(produto, campo.value);
        if (unidadesDespacho[0]) {
          const { style } = campo;
          return (
            <Grid container style={style}>
              {_.get(produto, campo.value).map((un, index) => (
                <Grid container xs={12}>
                  <Grid item xs={12} lg={2}>
                    MASTER {index + 1}:
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    C: {Number(un.USU_ComUdp).toLocaleString("pt-br")}
                    {"cm"}
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    L: {Number(un.USU_LarUdp).toLocaleString("pt-br")}
                    {"cm"}
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    A: {Number(un.USU_AltUdp).toLocaleString("pt-br")}
                    {"cm"}
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    Peso Líq.: {Number(un.USU_PesLiq).toLocaleString("pt-br")}
                    {"kg"}
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    Peso Bruto: {Number(un.USU_PesBru).toLocaleString("pt-br")}
                    {"kg"}
                  </Grid>
                  <Grid item xs={12} lg={1}>
                    Qtd:{" "}
                    {typeof un.qtdUni !== "undefined" && un.qtdUni
                      ? parseInt(un.qtdUni)
                      : 0}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          );
        }

        return "";
      case "inner":
        const { style } = campo;
        return (
          <Grid container style={style}>
            <Grid item xs={12} lg={2} className={classes.box}>
              INNER:
            </Grid>
            <Grid item xs={12} lg={1}>
              C: {Number(produto.USU_ComInner || 0).toLocaleString("pt-br")}
              {"cm"}
            </Grid>
            <Grid item xs={12} lg={1}>
              L: {Number(produto.USU_LarInner || 0).toLocaleString("pt-br")}
              {"cm"}
            </Grid>
            <Grid item xs={12} lg={1}>
              A: {Number(produto.USU_AltInner || 0).toLocaleString("pt-br")}
              {"cm"}
            </Grid>
            <Grid item xs={12} lg={2}>
              Peso Bruto:{" "}
              {Number(produto.USU_PesInner || 0).toLocaleString("pt-br")}
              {"kg"}
            </Grid>
            <Grid item lg={2} />
            <Grid item xs={12} lg={1}>
              Qtd: {Number(produto.USU_QtdInner || 0).toLocaleString("pt-br")}
            </Grid>
            <Grid item xs={12} lg={1}>
              Inner:{" "}
              {Number(produto.USU_ComInner) === 0 &&
                Number(produto.USU_LarInner) === 0 &&
                Number(produto.USU_AltInner) === 0 &&
                Number(produto.USU_PesInner) === 0
                ? "NÃO"
                : "SIM"}
            </Grid>
          </Grid>
        );

      case "produtoEmbalagem":
        if (loadingData) {
          return (
            <div className={classes.loading}>
              {`
              Produto com Embalagem ->
              C: ${<CircularProgress variant="indeterminate" size="20px" />}
              L: ${<CircularProgress variant="indeterminate" size="20px" />}
              A: ${<CircularProgress variant="indeterminate" size="20px" />}
              `}
            </div>
          );
        }
        return (
          <div style={style}>
            {`
              Produto com Embalagem ->
              C: ${Number(produto.USU_ProEmbCom || 0).toLocaleString("pt-br")}
              L: ${Number(produto.USU_ProEmbLar || 0).toLocaleString("pt-br")}
              A: ${Number(produto.USU_ProEmbAlt || 0).toLocaleString("pt-br")}
            `}
          </div>
        );

      case "reservados":
        if (loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (produto && Number(produto.totCarteira) > 0) {
          return (
            <div>
              <ModalReservados
                titulo={campo?.label}
                total={parseNumberFormat(produto.totCarteira)}
                loading={false}
                offline={offline}
                codPro={produto?.codPro}
                history={history}
                permissions={permissions}
                handleSnackbar={handleSnackbar}
              />
            </div>
          );
        }


        return <div>{campo.label}: 0</div>;

      case "bloqueado":
        if (loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (produto && Number(produto.totCarteira) > 0) {
          return (
            <div>
              <ModalBloqueado
                titulo={campo?.label}
                codPro={produto.codPro}
                history={history}
                totReserva={produto.totReserva}
                permissions={permissions}
                rows={produto?.reservasVendas || []}
                handleSnackbar={handleSnackbar}
              />
            </div>
          );
        }

        return <div>{`${campo.label}: ${0}`}</div>;

      case "picking":
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (produto && Number(produto.totPicking > 0)) {
          return (
            <div>
              <ModalPicking
                titulo={campo?.label}
                pedidos={produto?.pickings || []}
                totPicking={produto.totPicking}
                history={history}
                permissions={permissions}
                isDesktop={isDesktop}
                handleSnackbar={handleSnackbar}
              />
            </div>
          );
        }

        if (isDesktop) {
          return (
            <Tooltip placement="bottom-start" aria-label={campo.label} title={'Reserva Exclusiva'}>
              <div className={classes.box}>
                <span style={{ marginRight: '5px' }}>
                  {campo.label}: 0
                </span>
                <BsInfoCircle size={16} style={{ color: 'blue' }} />
              </div>
            </Tooltip>
          );
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '15px',
              marginBottom: '15px'
            }}
            onClick={() => {
              handleSnackbar({
                message: 'Reserva Exclusiva',
                variant: 'info',
                duration: 100000
              })
            }}
          >
            <span style={{ marginRight: '5px' }}>
              {campo.label}: 0
            </span>
            <BsInfoCircle size={16} style={{ color: 'blue' }} />
          </div>
        )

      case "container":
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (isNacional) {

          if (totNot > 0 && fabricados && fabricados.length > 0) {
            return (
              <ModalFabricado
                history={history}
                fabricados={fabricados}
                totNot={totNot}
                permissions={permissions}
                handleSnackbar={handleSnackbar}
              />
            );
          }
          return <div>{`Fabricado total: ${totNot}`}</div>;
        } else {
          if (totalContainer > 0) {
            return (
              <div>
                <ModalContainer
                  rowsContainer={rowsContainer}
                  history={history}
                  totalContainer={totalContainer}
                  codPro={produto.codPro}
                  offline={offline}
                  permissions={permissions}
                  handleSnackbar={handleSnackbar}
                />
              </div>
            );
          }

          return (
            <div>
              {campo.label}: {parseNumberFormat(totalContainer)}
            </div>
          );
        }

      case "embarcado":
        if (isNacional) {
          return <></>
        }
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (totalEmbarcado > 0) {
          return (
            <ModalEmbarcado
              qtd={parseNumberFormat(totalEmbarcado)}
              codPro={produto?.codPro}
              codEmp={empresa}
              history={history}
              permissions={permissions}
              handleSnackbar={handleSnackbar}
            />
          );
        }
        return (
          <div>{`${campo.label}: ${totalEmbarcado === undefined ? 0 : totalEmbarcado
            }`}</div>
        );

      case "produzindo":
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (isNacional) {
          if (totFab > 0 && fabricando && fabricando.length > 0) {
            return (
              <ModalFabricando
                history={history}
                fabricando={fabricando}
                totFab={totFab}
                permissions={permissions}
                handleSnackbar={handleSnackbar}
              />
            );
          }
          return <div>{`Fabricando nacional: ${totFab}`}</div>;
        } else {
          if (totalProduzindo > 0) {
            return (
              <ModalProduzindo
                qtd={parseNumberFormat(totalProduzindo)}
                codPro={produto?.codPro}
                codEmp={empresa}
                history={history}
                permissions={permissions}
                handleSnackbar={handleSnackbar}
              />
            );
          }

          return (
            <div>{`${campo.label}: ${totalProduzindo === undefined ? 0 : totalProduzindo
              }`}</div>
          );
        }

      case "fabricante":
        return (
          <div>
            {campo.label}: {_.get(produto, campo.value)}
          </div>
        );
      case "tipoEmbalagem":
        return (
          <div>
            {campo.label}:{" "}
            <a
              href={"#"}
              onClick={(e) => foto(e, getUrlImagemEmbalabem(produto.codPro))}
              target="_blank"
              rel="noopener noreferrer"
            >
              {_.get(produto, campo.value)}
            </a>
          </div>
        );
      case "peso":
        return (
          <div>
            {campo.label}:{" "}
            {Number(_.get(produto, campo.value) || 0).toLocaleString("pt-br")}
            {"kg"}
          </div>
        );
      case "scrolled":
        return (
          <div className={classes.scrolled}>
            {campo.label}:{" "}
            <div style={{
              maxHeight: 100,
              padding: 5,
              display: 'block',
              overflow: 'auto',
              border: "1px solid #ccc",
              borderRadius: 4,
              padding: "18.5px 14px",
            }}>
              {ReactHtmlParser(_.get(produto, campo.value))}
            </div>
          </div>
        );
      case "cm":
        return (
          <div className={classes.box}>
            {campo.label}:{" "}
            {Number(_.get(produto, campo.value) || 0).toLocaleString("pt-br")}
            {"cm"}
          </div>
        );
      case "ml":
        return (
          <div className={classes.box}>
            {campo.label}:{" "}
            {Number(_.get(produto, campo.value) || 0).toLocaleString("pt-br")}
            {"ml"}
          </div>
        );
      case "importacao":
        return (
          <div className={classes.box}>
            {campo.label}: {_.get(produto, campo.value)}
          </div>
        );
      case "fobs":
        return (
          <ModalTabelaFob
            codPro={produto?.codPro}
            history={history}
            permissions={permissions}
            handleSnackbar={handleSnackbar}
            produto={produto}
          />
        )
      case "dt_embarcado":
        if (isNacional) {
          return <></>
        }
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        const dtEmbarcado = _.get(produto, campo.value);
        if (dtEmbarcado === "01/01/1970" || !totalEmbarcado) {
          return <div className={classes.box}>{campo.label}: </div>;
        }

        return (
          <div className={classes.box}>
            {campo.label}: {_.get(produto, campo.value)}
          </div>
        );
      case "dt_produzindo":
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        if (isNacional) {
          if (totFab > 0 && fabricando && fabricando.length > 0) {
            const dateFab = getDateFab(fabricando);
            return <div>{`Dt Fabricando:${dateFab}`}</div>;
          }
          return <div>{`Dt Fabricando:`}</div>;
        } else {
          const dtProduzindo = _.get(produto, campo.value);
          if (!produto || dtProduzindo === "01/01/1970") {
            return <div className={classes.box}>{campo.label}: </div>;
          }

          return (
            <div className={classes.box}>
              {campo.label}: {_.get(produto, campo.value)}
            </div>
          );
        }
      case "estruturakit":
        if (loadingStructureKit) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }


        return (
          <FormControlLabel
            className={classes.controlLabel}
            labelPlacement="start"
            label={campo.label}
            control={
              <Checkbox
                color="primary"
                checked={isKit}
                style={{ padding: "0" }}
              />
            }
          />
        );
      case "estruturaunkit":
        if (loadingStructureKit) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }

        return (
          <FormControlLabel
            className={classes.controlLabel}
            labelPlacement="start"
            label={campo.label}
            control={
              <Checkbox
                color="primary"
                checked={isUnKit}
                style={{ padding: "0" }}
              />
            }
          />
        );

      case "qtd_embarcado":
        if (isNacional) {
          return <></>
        }
        if (loadingNarwal || loadingData) {
          return (
            <div className={classes.loading}>
              {campo.label}:
              <CircularProgress variant="indeterminate" size="20px" />
            </div>
          );
        }
        return (
          <div className={classes.box}>
            {campo.label}:{" "}
            {!totalEmbarcado
              ? 0
              : parseNumberFormat(_.get(produto, campo.value))}
          </div>
        );

      default:
        switch (campo.label) {
          case "Físico":
            if (loadingData) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            return (
              <div className={classes.box}>
                Físico: {parseNumberFormat(produto.totFisico)}
              </div>
            );

          case "Disp para Venda":
          case "Disp para Carga":
            if (loadingNarwal || loadingData) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            if (isDesktop) {
              return (
                <Tooltip placement="bottom-start" aria-label={campo.label} title={campo.label === 'Disp para Venda' ? msgToolTipDispFat : msgToolTipDispCar}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '15px'
                    }}
                  >
                    <span style={{ marginRight: '5px' }}>
                      {campo.label}: {campo.label === 'Disp para Venda' ? parseNumberFormat(produto.dispVenda) : parseNumberFormat(produto.dispCarga)}
                    </span>
                    <BsInfoCircle size={16} style={{ color: 'blue' }} />
                  </div>
                </Tooltip>
              );
            }
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '15px',
                  marginBottom: '15px'
                }}
                onClick={() => {
                  handleSnackbar({
                    message: campo.label === 'Disp para Venda' ? msgToolTipDispFat : msgToolTipDispCar,
                    variant: 'info',
                    duration: 100000
                  })
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  {campo.label}: {campo.label === 'Disp para Venda' ? produto.dispVenda : produto.dispCarga}
                </span>
                <BsInfoCircle size={16} style={{ color: 'blue' }} />
              </div>
            );

          case "Disp1":
            let labelDisp1 = 'Disp1'
            const msgTooltip = isNacional ? 'Disp para Venda + Fabricando' : 'Disp para Venda + Embarcado'

            if (loadingNarwal || loadingData) {
              return (
                <div className={classes.loading}>
                  {labelDisp1}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            if (isDesktop) {
              return (
                <Tooltip placement="bottom-start" aria-label={campo.label} title={msgTooltip}>
                  <div className={classes.box}>
                    <span style={{ marginRight: '5px' }}>
                      {labelDisp1}: {produto.totalDisp1_f}
                    </span>
                    <BsInfoCircle size={16} style={{ color: 'blue' }} />
                  </div>
                </Tooltip>
              );
            }

            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '15px',
                  marginBottom: '15px'
                }}
                onClick={() => {
                  handleSnackbar({
                    message: msgTooltip,
                    variant: 'info',
                    duration: 100000
                  })
                }}
              >
                <span style={{ marginRight: '5px' }}>
                  {labelDisp1}: {produto.totalDisp1_f}
                </span>
                <BsInfoCircle size={16} style={{ color: 'blue' }} />
              </div>
            )


          case "Disp2 (Disp1 + Produzindo)":
            if (isNacional) {
              return <></>
            }
            if (loadingNarwal || loadingData) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            const labelDisp2 =
              isNacional
                ? "Disp2 (Disp1 + Fabricando)"
                : "Disp2 (Disp1 + Produzindo)";
            return (
              <div className={classes.box}>
                {labelDisp2}: {produto.totalDisp2_f}
              </div>
            );

          case "Qtd Produzindo 1":
            if (loadingNarwal || loadingData) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            if (totFab > 0 && fabricando && fabricando.length > 0) {
              return (
                <div className={classes.box}>
                  Qtd Fabricando:{" "}
                  {parseNumberFormat(getQtdFabByDate(fabricando))}
                </div>
              );
            }

            return (
              <div className={classes.box}>
                {campo.label}: {parseNumberFormat(_.get(produto, campo.value))}
              </div>
            );

          case "Em Revisão":
          case "Em Inspeção":
            if (loadingData) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            return (
              <div className={classes.box}>
                {campo.label}: {parseNumberFormat(_.get(produto, campo?.value || 0))}
              </div>
            );

          case "CJ02 - Falta Container":
          case "CJ03 - Avarias Sucata":
          case "CJ30 - Avarias Venda":
          case "CJ33 - Defeito Embalagem":
            const isDiretor = permissions && permissions.length > 0
              ? permissions.some(permission =>
                permission.displayName === 'Espelho - Diretoria'
              )
              : false

            if (!isDiretor) return <></>
            if (loadingData) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            return (
              <div className={classes.box}>
                {campo.label}: {parseNumberFormat(_.get(produto, campo?.value || 0))}
              </div>
            );

          case "Total Bloqueado":
            if (loadingData) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            return (
              <div className={classes.box}>
                {campo.label}: {parseNumberFormat(produto?.totBloqueado || 0)}
              </div>
            );

          case "Processamento Unkit":
            if (loadingStructureKit) {
              return (
                <div className={classes.loading}>
                  {campo.label}:
                  <CircularProgress variant="indeterminate" size="20px" />
                </div>
              );
            }
            return (
              <div className={classes.box}>
                {campo.label}: {parseNumberFormat(totalProcUnKit)}
              </div>
            );

          default:
            return (
              <div className={classes.box}>
                {campo.label}: {_.get(produto, campo.value)}
              </div>
            );
        }
    }
  };

  const havePermissionToField = (field) => {
    const fieldsVerifyPermission = [
      'Físico',
      'Pedidos em Carteira',
      'Pré-Faturas em Carga',
      'Reservas para Pedido de Venda',
      'Em Inspeção',
      'Em Trânsito',
      'Em Qualidade',
      'Em Revisão',
      'Container',
      'Disp para Carga',
      'CJ02 - Falta Container',
      'CJ03 - Avarias Sucata',
      'CJ30 - Avarias Venda',
      'CJ33 - Defeito Embalagem',
      'Processamento Unkit'
    ]
    const isGroupHaveField = permissions.some((group) =>
      group.displayName === "Espelho - RCA"
    );

    if (fieldsVerifyPermission.indexOf(field) !== -1) {
      return !isGroupHaveField
    }

    if (field === 'Informaçao de Cód. Substituído') {
      const hasImportPermission = permissions.some((group) =>
        group.displayName === "Espelho - Importação" || group.displayName === 'Espelho - Diretoria'
      );
      return hasImportPermission;
    }
    return true
  }

  const keyPressCodigo = (e) => {
    const key = e.keyCode || e.which;
    if (key === 13) {
      const value = e.target.value;
      if (!value) {
        setProduto(undefined);
        history.push(`/`);
        return;
      }
      history.push(`/produto/${value}`);
      setProduto(undefined)
      fillProduto(e.target.value, empresa);
    }
  };

  const getTotalDisp1 = (produto) => {
    const totalEmbarcado = Number(produto?.USU_QtdPrevTot) || 0;
    const dispFat = Number(produto?.dispVenda) || 0;
    const totalDisp1 = totalEmbarcado + dispFat;
    return totalDisp1;
  };

  const getTotalDisp2 = (produto, compraNacional) => {
    const totalProduzindo = compraNacional ? Number(produto.totFab) || 0 : Number(produto?.USU_QtdProduzT) || 0;
    const totalDisp1 = Number(produto?.totalDisp1) || 0;
    const totalDisp2 = totalProduzindo + totalDisp1;

    return totalDisp2;
  };

  const inicializaVariaveis = () => {
    setProduto(undefined);
    setRowsContainer([]);
    setEstruturaCompleta([])
    setTotalContainer(0);
    setTotalEmbarcado(0);
    setTotalProduzindo(0);
    setTotalBid(0)
    setTotalProcUnkit(0)
    setLoadingEspelho(false);
    setIsNacional(false);
    setLoadingData(false)
  }

  const fillProduto = async (codigoProduto, empresa) => {
    if (!codigoProduto) {
      setProduto(undefined);
      return;
    }

    if (codigoProduto === produto?.codPro) return

    try {
      inicializaVariaveis()
      setOpenSnack(true);
      setMessageSnack("Carregando informações do produto");
      setVariantSnack("info");
      const param_espelho = {
        codEmp: empresa,
        codPro: codigoProduto,
      };
      const res_produto = await axios.get(`${endpoint_api}/produto`, {
        params: param_espelho,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setProduto(res_produto.data.produto);
      const res = await handleSetData(codigoProduto, res_produto.data.produto, empresa)
      if (res.produtoEspelho) {
        await handleSetStrucutreKit(codigoProduto, res.produtoEspelho, res.nfsNaoIntegrada, empresa)
      }
      setDescricaoBusca("");

    } catch (error) {
      console.log(error, "erro fill produto");
      if (error?.response?.status === 401) {
        const codEmp = localStorage.getItem("codigodaempresa");
        localStorage.clear();
        localStorage.setItem("codigodaempresa", codEmp);
        history.push("/login?logout=true");
      }
      const message = error?.response?.data?.message || error.message;
      handleSnackbar({ message, variant: 'error' })
      inicializaVariaveis()
    }
  };

  const handleSetDataNarwal = async (codigoProduto, compraNacional, produtoEspelho, codEmp) => {
    let nfsNaoIntegrada = 0;
    if (!compraNacional) {
      try {
        setLoadingNarwal(true);
        const route = `${process.env.REACT_APP_API_NARWAL_TEMPORARIA}/get-container-embarcado/${codEmp}/${codigoProduto}`;
        const res_narwal = await axios.get(route);
        const container = res_narwal.data;
        nfsNaoIntegrada = container.totalNfNaoIntegrada;
        produtoEspelho.USU_DtProduz1 = container.produzindo.data;
        produtoEspelho.USU_QtdProduzT = container.produzindo.saldo;
        produtoEspelho.USU_QtdProduz1 = container.produzindo.qtdProduzindo;

        produtoEspelho.USU_DtPrev1 = container.embarcado.data;
        produtoEspelho.USU_QtdPrevTot = container.embarcado.saldo;
        produtoEspelho.USU_QtdPrev1 = container.embarcado.qtdEmbarque;

        handleSetEntradaContainer(
          produtoEspelho.containers,
          container.totalEmbarcado
        );

        setTotalBid(container.totalBid);
        setTotalEmbarcado(container.embarcado.saldo);
        setTotalProduzindo(container.produzindo.saldo);

        if (nfsNaoIntegrada > 0) {
          const message = "Este produto tem nota emitida e ainda não atualizada no ERP, as informações de quantidade estão DESATUALIZADAS. Contate a área responsável para proceder com a atualização.";
          handleSnackbar({ message, variant: 'warning' })
        }
      } catch (error) {
        throw new Error(error?.response?.data?.message || error.message);
      } finally {
        setLoadingNarwal(false);
      }
    }
    return {
      nfsNaoIntegrada,
      produtoEspelho
    }
  }

  const handleSetData = async (codigoProduto, produtoEspelho, codEmp) => {
    try {
      setLoadingData(true)
      const message = "Carregando informações do estoque..."
      const compraNacional = getIsNacional(produtoEspelho)
      const body = {
        codEmp,
        codPro: codigoProduto,
        produto: produtoEspelho
      };

      const options = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }

      handleSnackbar({ message, duration: 20000 })

      const { data } = await axios.post(`${endpoint_api}/data-inventory`, body, options);
      const resDataNarwal = await handleSetDataNarwal(codigoProduto, compraNacional, data.produto, codEmp)
      const nfsNaoIntegrada = resDataNarwal.nfsNaoIntegrada

      produtoEspelho = resDataNarwal.produtoEspelho
      produtoEspelho.totalDispFat = data.dispVenda;
      produtoEspelho.totalDisp1 = getTotalDisp1(produtoEspelho);
      produtoEspelho.totalDisp2 = getTotalDisp2(produtoEspelho, compraNacional);

      produtoEspelho.totalDispFat_f = parseNumberFormat(
        data.dispVenda
      );
      produtoEspelho.totalDisp1_f = parseNumberFormat(
        produtoEspelho.totalDisp1
      );
      produtoEspelho.totalDisp2_f = parseNumberFormat(
        produtoEspelho.totalDisp2
      );

      setProduto({ ...produtoEspelho });
      setLoadingEspelho(false);
      return {
        produtoEspelho,
        nfsNaoIntegrada
      }
    } catch (error) {
      const message = error?.response?.data?.message || error.message;
      console.log(error, 'error set data')
      handleSnackbar({ message, duration: 10000, variant: 'error' })
      inicializaVariaveis()
      return null
    } finally {
      setLoadingData(false)
    }
  }

  const handleSetStrucutreKit = async (codigoProduto, produtoEspelho, nfsNaoIntegrada, codEmp) => {
    try {
      setLoadingStructureKit(true)
      const message = "Carregando informações do kit..."
      const compraNacional = getIsNacional(produtoEspelho)

      const options = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }

      const body = {
        codEmp,
        codPro: codigoProduto,
        produto: {}
      };

      handleSnackbar({ message, duration: 20000 })

      const { data } = await axios.post(`${endpoint_api}/data-structure-kit`, body, options);
      setEstruturaCompleta(data.produto.estruturaCompleta)
      setTotalProcUnkit(data.produto.totalProcUnkit)
      setIsKit(data.produto.isKit)
      setIsUnKit(data.produto.isUnKit)
      setIsNacional(compraNacional)

      if (nfsNaoIntegrada === 0) {
        handleSnackbar({ message: 'Produto carregado com sucesso', duration: 3000 })
      }

      setLoadingEspelho(false);
    } catch (error) {
      const message = error?.response?.data?.message || error.message;
      console.log(error, 'error set kit')
      inicializaVariaveis()
      handleSnackbar({ message, duration: 10000, variant: 'error' })
    } finally {
      setLoadingStructureKit(false)
    }
  }

  const handleSetEntradaContainer = (entradaResponse, totalEmbarcado) => {
    if (entradaResponse) {
      const totalContainer = entradaResponse.reduce(
        (total, container) =>
          parseInt(total) + parseInt(container?.quantidade || 0),
        0
      );
      setTotalContainer(totalContainer + parseInt(totalEmbarcado));
      setRowsContainer(entradaResponse);
    } else {
      setRowsContainer([]);
      setTotalContainer(parseInt(totalEmbarcado))
    }
  };

  const foto = (event, url) => {
    event.preventDefault();
    window.open(
      url,
      "foto",
      "scrollbars=no,resizable=no,toolbar=no,location=no,directories=no,status=no,menubar=no,top=300,left=500, width=300, height=300"
    );
  };

  const handleLogoff = (e) => {
    const loginApi = process.env.REACT_APP_LOGIN_API;
    const frontUrl = process.env.REACT_APP_FRONT_URL;
    const codigodaempresa = localStorage.getItem("codigodaempresa");

    localStorage.clear();
    localStorage.setItem("codigodaempresa", codigodaempresa);
    window.location.href = `${loginApi}/signout?redirectTo=${frontUrl}/login?logout=true`;
  };

  const handleSetStatePanel = (expanded, panelName) => {
    const index = statePainels.findIndex((grupo) => grupo.name === panelName);
    const copyState = [...statePainels];
    const state = {
      name: panelName,
      value: expanded,
    };
    if (index !== -1) {
      const copyState = [...statePainels];
      copyState[index] = state;
      setStatePainels(copyState);
      localStorage.setItem("statePanels", JSON.stringify(copyState));
    } else {
      copyState.push(state);
      setStatePainels(copyState);
      localStorage.setItem("statePanels", JSON.stringify(copyState));
    }
  };

  const getStatePanel = (grupo) => {
    const state = statePainels.find((state) => state.name === grupo);
    return state ? state.value : true;
  };

  const parseCodigoBarras = (codBar) => {
    if (codBar) {
      return produto?.codBar.substring(0, 8) === "26102895"
        ? produto?.codBar.padStart(12, 0)
        : produto?.codBar.substring(0, 6) !== "883314"
          ? produto?.codBar.padStart(13, 0)
          : produto?.codBar;
    }

    return "";
  };

  const parseUnidadeDespacho = (produto) => {
    if (produto?.unidadesDespacho) {
      return produto
        .unidadesDespacho
        .map(
          (unidadeDespacho, index) =>
            <Typography>{`DUN${index + 1}: ${unidadeDespacho?.codBar || ''}`}</Typography>
        )
    }
  };

  const contentDesk = (
    <>
      <div className={classes.logoff}>
        <Button
          variant="contained"
          color="primary"
          style={{ position: "absolute", top: "20px", right: "20px" }}
          id="btnLogoff"
          onClick={handleLogoff}
        >
          Desconectar
        </Button>
      </div>
      <div className={classes.headerRoot}>
        <Button variant="outlined" color="primary" onClick={findPrevious}>
          {"<"}
        </Button>
        <div className={classes.headerTitle}>
          <h2
            style={{
              width: "auto",
              display: "inline-block",
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            Cadastro de Produtos - Senior -
          </h2>
          <FormControl className={classes.selectRoot}>
            <Select
              value={empresa}
              onChange={(e) => handleChangeEnterprise(Number(e.target.value))}
            >
              <MenuItem value={1}>
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  WOLFF
                </span>
              </MenuItem>
              <MenuItem value={2}>
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  LYOR
                </span>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button variant="outlined" color="primary" onClick={findNext}>
          {">"}
        </Button>
      </div>
      <Grid
        style={{ paddingLeft: 20, paddingRight: 20 }}
        container
        direction="row"
      >
        <div
          className={classes.boxProduto}
          style={{ [produto ? "" : "margin"]: "0 auto" }}
        >
          <div className={classes.fotoProduto}>
            {produto && (
              <a href={"#"} onClick={(e) => foto(e, produto.imageUrl)}>
                <img
                  src={produto.imageUrl}
                  height="156"
                  width="156"
                  alt="Imagem do Produto"
                  style={{ marginRight: 10 }}
                />
              </a>
            )}
          </div>
          <div className={classes.boxSearch}>
            <div className={classes.fieldsSearch}>
              <TextField
                className={classes.textBusca}
                id="txtCodPro"
                label="Cod. do Produto"
                margin="dense"
                variant="filled"
                value={codigoBusca}
                onChange={(e) => setCodigoBusca(e.target.value)}
                onBlur={(e) => fillProduto(e.target.value, empresa)}
                onKeyPress={keyPressCodigo}
              />
              <SearchProductByName
                codEmpresa={empresa}
                produto={produto}
                fillProduto={fillProduto}
                setCodigoBusca={setCodigoBusca}
                selectedProduct={descricaoBusca}
                setSelectedProduct={setDescricaoBusca}
              />
            </div>
            <div className={classes.textDescricao}>
              {produto && (
                <>
                  <Typography>{`DESCRIÇÃO: ${produto?.desPro || ""
                    }`}</Typography>
                  <Typography>
                    {`EAN: ${parseCodigoBarras(produto?.codBar || "")} `}
                  </Typography>
                  {parseUnidadeDespacho(produto)}
                </>
              )}
            </div>
          </div>
        </div>
        {
          loadingStructureKit ? (
            <div className={classes.boxEstruturaKit}>
              <div
                className={classes.loading}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold'
                }}
              >
                {'Carregando Estrutura Kit: '}
                <CircularProgress variant="indeterminate" size="20px" />
              </div>
            </div>
          )
            : (
              <div
                className={classes.boxEstruturaKit}
                style={{ [produto ? "" : "display"]: "none" }}
              >
                {produto && (
                  <EstruturaCompleta
                    estruturaCompleta={estruturaCompleta}
                    goToProduct={goToProductKit}
                    openImage={foto}
                  />
                )}
              </div>
            )
        }

      </Grid>
    </>
  );

  const contentMobile = (
    <>
      <div className={classes.logoff}>
        <Button
          variant="contained"
          color="primary"
          id="btnLogoff"
          onClick={handleLogoff}
        >
          Desconectar
        </Button>
      </div>
      <div className={classes.headerRoot}>
        <Button variant="outlined" color="primary" onClick={findPrevious}>
          {"<"}
        </Button>
        <div className={classes.headerTitle}>
          <h2
            style={{
              width: "auto",
              display: "inline-block",
              marginLeft: 10,
              marginRight: 10,
              marginTop: "0px",
              marginBottom: "0px",
              fontSize: "20px",
            }}
          >
            Cadastro de Produtos
          </h2>
          <FormControl className={classes.selectRoot}>
            <Select
              value={empresa}
              onChange={(e) => handleChangeEnterprise(Number(e.target.value))}
            >
              <MenuItem value={1}>
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  WOLFF
                </span>
              </MenuItem>
              <MenuItem value={2}>
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  LYOR
                </span>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button variant="outlined" color="primary" onClick={findNext}>
          {">"}
        </Button>
      </div>
      <Grid
        style={{ paddingLeft: 20, paddingRight: 20 }}
        container
        direction="row"
      >
        <div
          className={classes.boxProduto}
          style={{ [produto ? "" : "margin"]: "0 auto" }}
        >
          <div className={classes.fotoProduto}>
            {produto && (
              <a href={"#"} onClick={(e) => foto(e, produto.imageUrl)}>
                <img
                  src={produto.imageUrl}
                  height="156"
                  width="156"
                  alt="Imagem do Produto"
                  style={{ marginRight: 10 }}
                />
              </a>
            )}
          </div>
          <div className={classes.boxSearch}>
            <div className={classes.fieldsSearch}>
              <TextField
                className={classes.textBusca}
                id="txtCodPro"
                label="Cod. do Produto"
                margin="dense"
                variant="filled"
                value={codigoBusca}
                onChange={(e) => setCodigoBusca(e.target.value)}
                onBlur={(e) => fillProduto(e.target.value, empresa)}
                onKeyPress={keyPressCodigo}
              />
              <SearchProductByName
                codEmpresa={empresa}
                produto={produto}
                fillProduto={fillProduto}
                setCodigoBusca={setCodigoBusca}
                selectedProduct={descricaoBusca}
                setSelectedProduct={setDescricaoBusca}
              />
            </div>
            <div className={classes.textDescricao}>
              {produto && (
                <>
                  <Typography>{`DESCRIÇÃO: ${produto?.desPro || ""
                    }`}</Typography>
                  <Typography>
                    {`EAN: ${parseCodigoBarras(produto?.codBar || "")} `}
                  </Typography>
                  {parseUnidadeDespacho(produto)}
                </>
              )}
            </div>
          </div>
        </div>

        {
          loadingStructureKit ? (
            <div className={classes.boxEstruturaKit}>
              <div
                className={classes.loading}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold'
                }}
              >
                {'Carregando Estrutura Kit: '}
                <CircularProgress variant="indeterminate" size="20px" />
              </div>
            </div>
          )
            : (
              <div
                className={classes.boxEstruturaKit}
                style={{ [produto ? "" : "display"]: "none" }}
              >
                {produto && (
                  <EstruturaCompleta
                    estruturaCompleta={estruturaCompleta}
                    goToProduct={goToProductKit}
                    openImage={foto}
                  />
                )}
              </div>
            )
        }
      </Grid>
    </>
  );

  return (
    <div>
      <BarraOffline
        dataAtualizacaoProduto={produto?.updated_at}
        showOffline={offline}
      />
      <Snackbar
        message={messageSnack}
        variant={variantSnack}
        open={openSnack}
        duration={durationSnack}
        onClose={() => setOpenSnack(false)}
      />
      {isDesktop ? contentDesk : contentMobile}
      <Grid
        style={{ paddingLeft: 20, paddingRight: 20 }}
        container
        direction="row"
      >
        {schema && produto
          ? schema.map((grupo) => (
            <Grid item xs={12} key={grupo.name} style={{ margin: "10px" }}>
              <ExpansionPanel
                expanded={getStatePanel(grupo.name)}
                onChange={(e, expanded) =>
                  handleSetStatePanel(expanded, grupo.name)
                }
              >
                <ExpansionPanelSummary
                  id={`panel${grupo.name}`}
                  expandIcon={<ExpandMore />}
                  style={{ fontWeight: "bold", fontSize: "16pt" }}
                >
                  {grupo.name}
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  <Grid container direction="row">
                    {grupo.fields.map((campo) => {
                      const isMobile = window.screen.width <= 940

                      return (
                        <Grid
                          {...campo}
                          item
                          xs={12}
                          lg={campo.size}
                          key={campo.label}
                          style={{
                            textAlign: "justify",
                            ...campo.style,
                            ...(isMobile && campo.value === 'caracteristicas' && {
                              marginBottom: '14px'
                            })
                          }}
                        >
                          {getField(campo)}
                        </Grid>
                      )
                    })}
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          ))
          : ""}
      </Grid>
    </div>
  );
}
