import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { validateToken } from "../Utils/Cookie";
import TabelaFob from "./TabelaFob";
import axios from "axios";
import * as XLSX from "xlsx";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import _ from "lodash";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  excelButton: {
    marginRight: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: '0.9rem'
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, onExportExcel, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.excelButton}
          onClick={onExportExcel}
          startIcon={<PiMicrosoftExcelLogoFill />}
        >
          Excel
        </Button>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "space-between",
    display: "flex",
  },
}))(MuiDialogActions);

export default function ModalTabelaFob({
  codPro,
  history,
  permissions,
  handleSnackbar,
  produto
}) {
  const [open, setOpen] = React.useState(false);
  const [fobs, setFobs] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  const syncTabelaFobs = async (codEmp) => {
    try {
      setLoader(true);
      setFobs([]);
      const endpoint_api = process.env.REACT_APP_API;
      const { data } = await axios.get(`${endpoint_api}/get-tabela-fob`, {
        params: {
          codPro,
          codEmp,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (data.fobs.length === 0) {
        handleSnackbar({
          message: "Sem dados para exibição",
          variant: "warning",
          duration: 2000,
        });
        setOpen(false);
      }

      setFobs(data.fobs);
    } catch (error) {
      const messageError = error?.response?.data?.message || error.message;
      handleSnackbar({
        message: messageError,
        variant: "error",
        duration: 2000,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleClickOpen = () => {
    const empresa = localStorage.getItem("codigodaempresa");
    if (!validateToken()) {
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      history.push("/login?logout=true");
    }

    if (permissions && permissions.length > 0) {
      const isImportacao = permissions.some(
        (permission) =>
          permission.displayName === "Espelho - Importação" ||
          permission.displayName === "Espelho - Diretoria" ||
          permission.displayName === "TI - Desenvolvimento"
      );
      if (!isImportacao) {
        handleSnackbar({
          message: "Acesso bloqueado",
          variant: "error",
          duration: 2000,
        });
        return;
      }
    }
    if (!codPro) {
      handleSnackbar({
        message: "Produto não informado",
        variant: "error",
        duration: 2000,
      });
      return;
    }
    setOpen(true);
    syncTabelaFobs(empresa);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExportExcel = () => {
    if (fobs.length === 0) {
      handleSnackbar({
        message: "Nenhum dado para exportar",
        variant: "warning",
        duration: 2000,
      });
      return;
    }

    const mapaChaves = {
      comprador: "Comprador",
      datacadastronwl: "Data Compra",
      descricaoproduto: "Descrição",
      fabricante: "Fabricante",
      id: "ID",
      moeda: "Moeda",
      pedidocompra: "Pedido",
      preco: "Preço",
      precoajustado: "Preço Ajustado",
      produto: "Produto",
      qtde: "Quantidade",
      statuspcnwl: "Status Pedido",
    };

    const empresa = localStorage.getItem("codigodaempresa");
    const onHold = _.get(produto, "USU_Onhold") === "N" ? "NÃO" : "SIM";
    const inativo = _.get(produto, "inativo") === "A" ? "NÃO" : "SIM";

    const fobsAtualizados = fobs.map((obj) => {
      const novoObj = {};
      for (const chave in obj) {
        const novaChave = mapaChaves[chave] || chave;
        novoObj[novaChave] = obj[chave];
      }


      const idIndex = Object.keys(novoObj).indexOf("ID");
      const entries = Object.entries(novoObj);
      entries.splice(idIndex + 1, 0, ["Empresa", empresa], ["Inativo", inativo], ["On Hold", onHold]);
      return Object.fromEntries(entries);
    });

    const worksheet = XLSX.utils.json_to_sheet(fobsAtualizados);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tabela FOB");

    XLSX.writeFile(workbook, "Tabela_FOB.xlsx");
    handleSnackbar({
      message: "Exportação concluída com sucesso",
      variant: "success",
      duration: 2000,
    });
  };

  return (
    <div>
      <a href={"#"} onClick={handleClickOpen}>
        Tabela FOB
      </a>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          onExportExcel={handleExportExcel}
        >
          Consulta Tabela FOB
        </DialogTitle>
        <DialogContent dividers>
          <TabelaFob
            setFobs={setFobs}
            fobs={fobs}
            loader={loader}
            permissions={permissions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
